@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200;300;400;500;600&display=swap');

pre {
    padding: 10px;
    white-space: pre-wrap;
    background-color: #333333;
    color: rgb(182, 182, 182);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    border-radius: 10px;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    font-size: 0.9rem;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;;
  }
  
  .ql-editor iframe {
    min-height: 300px;
  }
  
  .ql-editor img {
    max-height: 300px;
    object-fit: contain;
  }
